// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ausbildung-js": () => import("./../../../src/pages/ausbildung.js" /* webpackChunkName: "component---src-pages-ausbildung-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-dsv-js": () => import("./../../../src/pages/dsv.js" /* webpackChunkName: "component---src-pages-dsv-js" */),
  "component---src-pages-handwerkseite-js": () => import("./../../../src/pages/handwerkseite.js" /* webpackChunkName: "component---src-pages-handwerkseite-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konzept-js": () => import("./../../../src/pages/konzept.js" /* webpackChunkName: "component---src-pages-konzept-js" */),
  "component---src-pages-krefeld-js": () => import("./../../../src/pages/krefeld.js" /* webpackChunkName: "component---src-pages-krefeld-js" */),
  "component---src-pages-kundenseite-js": () => import("./../../../src/pages/kundenseite.js" /* webpackChunkName: "component---src-pages-kundenseite-js" */),
  "component---src-pages-meisterseite-js": () => import("./../../../src/pages/meisterseite.js" /* webpackChunkName: "component---src-pages-meisterseite-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-projekte-plan-dein-ding-js": () => import("./../../../src/pages/projekte/PlanDeinDing.js" /* webpackChunkName: "component---src-pages-projekte-plan-dein-ding-js" */),
  "component---src-pages-versprechen-js": () => import("./../../../src/pages/versprechen.js" /* webpackChunkName: "component---src-pages-versprechen-js" */),
  "component---src-pages-wischerseite-js": () => import("./../../../src/pages/wischerseite.js" /* webpackChunkName: "component---src-pages-wischerseite-js" */)
}

